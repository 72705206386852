import { PricingTable } from './pricingTable';

export class DomainService {
  constructor(content, state = '') {
    this.content = content;
    this.state = this._parseState(state);
  }

  withState(state) {
    return new DomainService(this.content, state);
  }

  describeFilter() {
    return [
      this._describeCategoryFilters(),
      this._describeSearch()
    ].filter(x => !!x).join(' ');
  }

  getStatistics() {
    return {
      total: this.content.plants.length,
      visible: this.getTreesAndShrubs().length,
    }
  }

  getPricing(id) {
    const treeOrShrub = this.getTreeOrShrub(id);
    if (treeOrShrub) return new PricingTable(treeOrShrub);
    return false;
  }

  getTreesAndShrubs() {
    // This is safe to cache because the filter is dependent on the state, which is immutable
    if (!this._cachedTreesAndShrubs) {
      const includeCategories = this._getCategoriesThatAreNotFilteredOut();
      this._cachedTreesAndShrubs = this.content.plants
        .filter(treeOrShrub => {
          const categoryMatches = includeCategories.indexOf(treeOrShrub.category.slug) >= 0
          const searchMatches = treeOrShrub.name.toLowerCase().match((this.state.search || '').trim().toLowerCase());
          return categoryMatches && searchMatches;
        });
    }
    return this._cachedTreesAndShrubs;
  }

  getTypes() {
    return this._getTypeArray().map(({ slug, categories, ...rest }) => ({
      ...rest,
      slug,
      isFilteredOut: this._isTypeFilteredOut(slug),
      categories: categories.map(({ slug: categorySlug, ...restOfCategory }) => ({
        ...restOfCategory,
        slug: categorySlug,
        isFilteredOut: this._isCategoryFilteredOut(categorySlug)
      }))
    }));
  }

  getCategories() {
    return this._getCategoryArray()
      .map(category => ({
        ...category,
        isFilteredOut: this._isCategoryFilteredOut(category.slug)
      }));
  }

  getTreeOrShrub(id) {
    const treesAndShrubs = this.getTreesAndShrubs();
    const index = treesAndShrubs.findIndex(x => x.id === id);
    const data = treesAndShrubs[index];
    if (!data) return false;
    return {
      ...data,
      previousId: treesAndShrubs[index - 1]?.id || false,
      nextId: treesAndShrubs[index + 1]?.id || false
    }
  }

  getSearchTerm() {
    return this.state.search;
  }

  getState() {
    return this._nextState();
  }

  excludeType(id) {
    const categoriesToExclude = this._getType(id).categories;
    const categories = this._getCategoriesThatAreNotFilteredOut();
    const doInclude = category => categoriesToExclude.indexOf(category) < 0;
    return this._nextState({ categories: categories.filter(doInclude) });
  }

  includeType(id) {
    const categoriesToInclude = this.content.types[id].categories;
    return this._nextState({ categories: new Set([...this._getCategoriesThatAreNotFilteredOut(), ...categoriesToInclude]) });
  }

  excludeCategory(id) {
    return this._nextState({ categories: this._getCategoriesThatAreNotFilteredOut().filter(slug => slug !== id) });
  }

  includeCategory(id) {
    return this._nextState({ categories: new Set([...this._getCategoriesThatAreNotFilteredOut(), id]) });
  }

  excludeAllCategoriesExcept(id) {
    return this._nextState({ categories: [id] });
  }

  setSearchTerm(search) {
    return this._nextState({ search });
  }

  _getType(id) {
    const type = this.content.types[id];
    if (!type) throw new Error(`Couldn't find type ${id}`);
    return type;
  }

  _getCategoriesThatAreNotFilteredOut() {
    return this.state.categories;
  }

  _getAllCategorySlugs() {
    return this._getCategoryArray().map(x => x.slug);
  }

  _getCategoryArray() {
    return Object.values(this.content.categories);
  }

  _getTypeArray() {
    return Object.values(this.content.types).map(type => ({ ...type, categories: type.categories.map(c => this.content.categories[c]) }));
  }

  _parseState(stateString) {
    const [categories, search] = stateString.split(';');
    return {
      categories: this._parseCategories(categories),
      search
    };
  }

  _parseCategories(categoriesString) {
    if (categoriesString === '_') return [];
    if (categoriesString) return categoriesString.split('+');
    return this._getAllCategorySlugs();
  }

  _nextState(update) {
    const newState = { ...this.state, ...update };
    const nextCategoryState = Array.from(newState.categories).join('+') || '_';
    return [nextCategoryState, newState.search].join(';');
  }

  _describeCategoryFilters() {
    const includedCategories = this._getCategoriesThatAreNotFilteredOut();
    if (includedCategories.length === this._getCategoryArray().length) {
      return 'all Trees and Shrubs';
    }
    const parts = this.getTypes().map(type => {
      if (type.isFilteredOut === 'PARTIAL') {
        const nestedParts = type.categories.filter(c => !c.isFilteredOut).map(c => c.name);
        return nestedParts;
      } else if (type.isFilteredOut === false) {
        return [type.name];
      }
    })
    return this._joinWordsForDescription(parts.flat());
  }

  _joinWordsForDescription(words) {
    const realWords = words.filter(x => !!x);
    if (realWords.length >= 4) return 'Various Types and Categories';
    if (realWords.length === 3) {
      const [one, two, three] = realWords;
      return `${one}, ${two}, and ${three}`;
    }
    return realWords.join(' and ');
  }

  _describeSearch() {
    if (this.state.search) {
      return `that match "${this.state.search}"`;
    }
    return '';
  }

  _isTypeFilteredOut(type) {
    const categoriesNotFilteredOut = this._getCategoriesThatAreNotFilteredOut();
    const typeCategories = this.content.types[type].categories;
    const includedTypeCategories = categoriesNotFilteredOut.filter(c => typeCategories.indexOf(c) >= 0);

    if (!includedTypeCategories.length) return true;
    if (includedTypeCategories.length === typeCategories.length) return false;
    return 'PARTIAL';
  }

  _isCategoryFilteredOut(category) {
    const categoriesNotFilteredOut = this._getCategoriesThatAreNotFilteredOut();
    return categoriesNotFilteredOut.indexOf(category) < 0;
  }
}
