import { useState, useEffect } from 'preact/hooks';

export function useFadingFlag({ waitSeconds, fadeSeconds}) {
  const [ state, setState ] = useState(false);

  const toggleOn = () => setState('show');

  useEffect(() => {
    switch(state) {
      case 'show':
        const showTimeout = setTimeout(() => setState('fading'), waitSeconds * 1000);
        return () => clearTimeout(showTimeout);
      case 'fading':
        const fadingTimeout = setTimeout(() => setState(false), fadeSeconds * 1000);
        return () => clearTimeout(fadingTimeout);
    }
  }, [state]);

  return [ { isVisible: !!state, isFading: state === 'fading' }, toggleOn ];
}
