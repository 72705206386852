import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import Icon from '../icons/totalTreeSolutions';
import { url } from '../../url';

const Header = () => (
  <nav class={style.header}>
    <Link href={url('')}><h1 class={style.headerBrand}>Total Tree Catalog</h1></Link>
    <a href="https://totaltreesolutions.ca/about-us.html" class={style.headerLogo}><span>Contact</span><Icon/></a>
  </nav>
);

export default Header;
