import { h } from 'preact';
import style from './style.css';

const Icon = () => (
  <svg class={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 128, 128">
    <defs>
      <clipPath id="a">
        <path d="M.843 18.022h127.04v91.433H.843z"/>
      </clipPath>
    </defs>
    <path d="M13.012 107.139l10.234-5.418-7.033-.553-7.192-13.593s-6.48 4.267-6.401 4.03c.079-.237 8.93 5.453 8.93 5.453l1.462 10.081zM104.524 101.59c0-.184 10.705 5.549 10.705 5.549l1.477-9.978 8.675-5.906-6.645-3.68-7.198 13.462-7.014.553z"/>
    <path d="M111.169 99.191l8.675-16.427S65.95 52.495 8.733 82.211c-.184 1.476 8.49 17.349 8.306 16.98 51.679-25.101 94.13 0 94.13 0z"/>
    <path d="M98.956 72.38s1.869.374 1.869.747c0 .374 4.486-9.905 2.804-22.052-6.915-32.144-38.499-33.043-38.872-33.043-.374 0-28.22-1.344-39.059 27.997-4.112 16.633 1.682 27.472 1.682 27.472l1.495-1.121s-5.607-14.39-1.495-25.977c12.147-28.967 37.003-26.538 37.003-26.538s29.341 0 37.377 31.397c1.495 12.521-2.804 21.118-2.804 21.118z"/>
    <path d="M66.12 67.579v-6.306l3.524-3.709.557-1.484 3.153 1.484 4.08-3.153 2.411 5.008 4.266.741 1.854-2.04 2.968 1.855 1.298-.927.185-1.113 3.895.556 1.669-1.298-.556-2.411 2.596-1.484-1.483-2.411.927-1.298-3.338-1.484 1.112-2.04-4.265-2.04 1.298-.927-5.379-5.564.928-1.484-1.298-1.669.556-1.298-2.411-1.855.556-1.113-3.709-.742-.185-.927-5.008-1.298-2.411-2.968-6.12.186-4.637 3.338-4.266-1.298-3.709.556-3.524.371-3.524 4.08-2.596-.185-1.299 1.484-1.669.185-.556 1.484-3.153 1.669v3.338l.927 2.597-3.524.927-.741 4.637-4.452.556-1.483 2.968 1.298 2.225 2.596-.927 1.299 3.153 3.153 1.298 2.782-1.484 1.483 1.484 2.04-1.298 6.492 2.411 1.854 1.484 1.855-1.855 2.04.371 1.484-.742s3.153 1.669 3.338 1.669c.186 0 1.484 1.669 1.484 1.669v4.637l-.742 2.597 4.08-.186z"/>
  </svg>
);

export default Icon;
