import { PricingTableField } from './PricingTableField';
import { PricingTableRangeField } from './PricingTableRangeField';
import {
  Money,
  Inches,
  FeetFromMeters,
  InchesFromMillimeters
} from './format';

const heightKeys = { lowKey: 'heightLowEndInMeters', highKey: 'heightHighEndInMeters' };
const caliperKeys = { lowKey: 'caliperLowEndInMillimeters', highKey: 'caliperHighEndInMillimeters' };

export const fields = [
  new PricingTableField({ key: 'price',          label: 'Price',   format: Money.format()                 }),
  new PricingTableField({ key: 'description',    label: 'Size'                                            }),
  new PricingTableField({ key: 'basketInInches', label: 'Basket',  format: Inches.format()                }),
  new PricingTableRangeField({ ...heightKeys,    label: 'Height',  format: FeetFromMeters.format()        }),
  new PricingTableRangeField({ ...caliperKeys,   label: 'Caliper', format: InchesFromMillimeters.format() }),
];

