import { PricingTableField } from './PricingTableField';

export class PricingTableRangeField extends PricingTableField {
  constructor({ lowKey, highKey, format, ...rest }) {
    super({ ...rest, format: PricingTableRangeField.formatRange(format) });
    this.lowKey = lowKey;
    this.highKey = highKey;
  }

  static formatRange(formatOne = (x => x)) {
    return values => values.map(formatOne).join(' - ');
  }

  appliesTo(dataKeys) {
    return dataKeys.has(this.lowKey) || dataKeys.has(this.highKey);
  }

  getValue(data) {
    return [
      data[this.lowKey],
      data[this.highKey]
    ].filter(x => !!x);
  }
}
