export class PricingTableField {
  constructor({ key, label, format }) {
    this.key = key;
    this.label = label;
    this.format = format || (x => x);
  }

  static keySet(items) {
    return new Set(items.map(x => Object.keys(x)).flat());
  }

  static appliesTo(items) {
    const keys = PricingTableField.keySet(items);
    return field => field.appliesTo(keys)
  }

  static formatValue(field, item, includedKeys) {
    if (!field.appliesTo(includedKeys)) return false;
    const value = field.getValue(item);
    if (!value) return '';
    return field.format(value);
  }

  appliesTo(keys) {
    return keys.has(this.key);
  }

  getValue(data) {
    return data[this.key];
  }

  getLabel() {
    return this.label;
  }
}
