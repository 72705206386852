import { RoundedNumber, MetersToFeet, MillimetersToInches } from './numberFormat';

export class Money {
  static _formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  static format() {
    return x => Money._formatter.format(x);
    
  }
}

export class WithSuffix {
  static format({ suffix }) {
    return x => x + suffix;
  }
}

export class Inches {
  static format() {
    const roundToOne = RoundedNumber.format({ digits: 1 });
    const withUnits = WithSuffix.format({ suffix: '″' });
    return x => withUnits(roundToOne(x));
  }
}

export class FeetFromMeters {
  static format() {
    const metersToFeet = MetersToFeet.format();
    const roundToOne = RoundedNumber.format({ digits: 1 });
    const withUnits = WithSuffix.format({ suffix: '′' });
    return x => withUnits(roundToOne(metersToFeet(x)));
  }
}

export class InchesFromMillimeters {
  static format() {
    const millimetersToInches = MillimetersToInches.format();
    const roundToOne = RoundedNumber.format({ digits: 1 });
    const withUnits = WithSuffix.format({ suffix: '″' });
    return x => withUnits(roundToOne(millimetersToInches(x)));
  }
}
