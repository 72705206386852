import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import Icon from '../icons/share';
import { url } from '../../url';
import { useFadingModal } from '../fadingModal';

const Footer = () => {
  const [ FadingModal, showFadingModal ] = useFadingModal({ waitSeconds: 0.8, fadeSeconds: 0.8 });

  function share() {
    navigator.clipboard.writeText(window.location.href)
      .then(showFadingModal);
  }

  return (
    <div class={style.footer}>
      <button onClick={share} class={style.footerShareButton}>
        <span>Share Link</span>
        <Icon/>
      </button>
      <div class={style.footerCopyright}>
        <span>Copyright Total Tree Solutions &copy; 2021 - All Rights Reserved</span>
      </div>
      <FadingModal>
        ✔ Copied link to the clipboard.
      </FadingModal>
    </div>
  );
}

export default Footer;
