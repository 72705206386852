import { h } from 'preact';
import style from './style.css';
import { useFadingFlag } from '../../hooks/useFadingFlag';

export function useFadingModal({ waitSeconds, fadeSeconds }) {
  const [{ isVisible, isFading }, showModal] = useFadingFlag({ waitSeconds, fadeSeconds });
  const FadingModal = ({ children }) => {
    if (!isVisible) return null;
    const maybeFadingStyle = isFading ? style.fadingModalContainer_FadeOut : '';
    return (
      <div class={style.fadingModalContainer + ' ' + maybeFadingStyle} style={`--fade-duration: ${fadeSeconds}s`}>
        <div class={style.fadingModalContent}>
          { children }
        </div>
      </div>
    );
  }

  return [ FadingModal, showModal ];
}
