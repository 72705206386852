import { useState } from 'preact/hooks';
import { domainFactory } from '../domain';
import content from '../content.json';

function useDomainState() {
  const urlHashState = location.hash?.slice(1) || '';
  const [ state, setInternalState ] = useState(urlHashState);
  const setState = newState => {
    if (location) {
      location.hash = newState;
    }
    setInternalState(newState);
  }
  const domain = domainFactory(content).withState(state);
  return [ domain, setState ];
}

export default useDomainState;
