import './style';
import { h } from 'preact';
import { Router } from 'preact-router';

import useDomain from './hooks/useDomain';
import Header from './components/header';
import Footer from './components/footer';

// Code-splitting is automated for `routes` directory
import Catalog from './routes/catalog';
import TreeOrShrub from './routes/treeOrShrub';

const App = () => {
  const [domain, setDomainState] = useDomain();
  return (
    <div id="app">
      <Header />
      <div id="app__content">
        <Router>
          <Catalog path="/" domain={domain} setDomainState={setDomainState}/>
          <TreeOrShrub path="/:id" domain={domain} setDomainState={setDomainState}/>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
