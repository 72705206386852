export class RoundedNumber {
  static format({ digits }) {
    return x => RoundedNumber._roundTo(x, digits)
  }

  static _roundTo(number, digits) {
    const factor = Math.pow(10, digits);
    return Math.round(number * factor) / factor;
  }
}

export class MetersToFeet {
  static _feetPerMeter = (1 / 0.3048);

  static format() {
    return meters => meters * MetersToFeet._feetPerMeter;
  }
}

export class MillimetersToInches {
  static _inchesPerMillimeter = (1 / 25.4);

  static format() {
    return millimeters => millimeters * MillimetersToInches._inchesPerMillimeter;
  }
}

