import { PricingTableField } from './PricingTableField';
import { fields } from './fields';

export class PricingTable {
  constructor({ sizes }) {
    this.items = sizes;
  }

  getHeaders() {
    return fields
      .filter(PricingTableField.appliesTo(this.items))
      .map(x => x.getLabel());
  }

  getRows() {
    const includedKeys = PricingTableField.keySet(this.items);
    return this.items.map(item => PricingTable._allFieldValues(item, includedKeys));
  }

  static _allFieldValues(items, includedKeys) {
    return fields
      .map(field => PricingTableField.formatValue(field, items, includedKeys))
      .filter(x => x !== false);
  }
}

